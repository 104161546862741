import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from '../components/Layout'
import Publications from '../components/Publications'
import { BannerDefault, TitleDefault } from '../components/Elements/Elements'

// CSS
import '../styles/PublicatiePage.scss'

function PublicatiePage({ data }) {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="book-page">
        <BannerDefault>
          <div className="row">
            <Img
              className="position-absolute banner-image"
              fluid={
                page.acf.publicaties_header.banner_publicaties_book.localFile
                  .childImageSharp.fluid
              }
            />
            <div className="col-12 col-lg-4 pb-4">
              <TitleDefault>
                {page.acf.publicaties_header.title_publicaties_book}
              </TitleDefault>
            </div>
          </div>
        </BannerDefault>
        <section>
          <div className="container py-5">
            <Publications pubYear={2025} />
            <Publications pubYear={2024} />
            <Publications pubYear={2023} />
            <Publications pubYear={2022} />
            <Publications pubYear={2021} />
            <Publications pubYear={2020} />
            <Publications pubYear={2019} />
            <Publications pubYear={2018} />
            <Publications pubYear={2017} />
            <Publications pubYear={2016} />
            <Publications pubYear={2015} />
            <Publications pubYear={2014} />
            <Publications pubYear={2013} />
            <Publications pubYear={2012} />
            <Publications pubYear={2012} />
            <Publications pubYear={2010} />
            <Publications pubYear={2006} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PublicatiePage

export const PublicatieQuery = graphql`
  query PublicatieQuery {
    wordpressPage(wordpress_id: { eq: 114 }) {
      title
      acf {
        publicaties_header {
          title_publicaties_book
          banner_publicaties_book {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
