import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { TitleAlt } from './Elements/Elements'

// Images
import arrow from '../img/arrow-menu.svg'

import '../styles/PublicatiePage.scss'

const Publications = ({ pubYear }) => {
  const { publication } = useStaticQuery(graphql`
    {
      publication: allWordpressWpPublicaties {
        edges {
          node {
            wpId: wordpress_id
            acf {
              year
              title
            }
          }
        }
      }
    }
  `)

  let publicationList = publication.edges

  if (pubYear) {
    publicationList = publicationList.filter(({ node: { acf: { year } } }) =>
      year.includes(pubYear)
    )
  }

  return (
    <>
      <div className="row">
        <TitleAlt>
          <img src={arrow} alt="" width="18" />
          {pubYear}
        </TitleAlt>
      </div>
      <div className="row pb-5">
        {publicationList.map(({ node: item }) => (
          <div className="col-lg-4 col-md-6 d-flex mb-5" key={item.wpId}>
            <div className="inner">
              <p className="mb-0">
                <span className="color-text-contrast font-weight-bold mr-1">
                  "
                </span>
                <span>{item.acf.title}</span>
                <span className="color-text-contrast font-weight-bold ml-1">
                  "
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Publications
